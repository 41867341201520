const serviceData = [
  {
    id: 1,
    title: "City Transfer",
    icon: "ri-map-pin-2-line",
    image:
      "https://img.freepik.com/free-photo/family-enjoying-views-car-trip_23-2149401822.jpg", // Add the image path or URL here
    desc: "Our city transfer services provide seamless transportation to and from key locations, including airports, hotels, and popular attractions. With a fleet of comfortable vehicles and professional drivers, we ensure timely and reliable service tailored to your schedule. Whether you're arriving in a new city or need to navigate local sites, our transfers make your travel experience stress-free and efficient, allowing you to focus on enjoying your journey.",
  },

  {
    id: 2,
    title: "Whole City Tour",
    icon: "ri-community-line",
    image:
      "https://cdn.getyourguide.com/img/tour/a5620a76c618e4f8.jpeg/146.jpg", // Add the image path or URL here
    desc: "Experience the highlights of the city with our comprehensive whole city tour! This guided adventure takes you to iconic landmarks, hidden gems, and local favorites, all while providing insightful commentary from knowledgeable guides. Enjoy a mix of cultural, historical, and scenic attractions, making it the perfect way to immerse yourself in the city's unique atmosphere and discover its rich heritage in just one day.",
  },

  {
    id: 3,
    title: "Unlimited Miles Car Rental",
    icon: "ri-roadster-line",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvGS5MpnBu_0zv53yP70UILGYyN9KTOoO42A&s", // Add the image path or URL here
    desc: "Enjoy the freedom to explore with our unlimited miles car rental service! Whether you're planning a road trip or need a vehicle for an extended stay, we offer a wide selection of well-maintained cars with no mileage restrictions.",
  },

  {
    id: 4,
    title: "Fast & Easy Booking",
    icon: "ri-timer-flash-line",
    image:
      "https://gomomentus.com/hs-fs/hubfs/ungerboeck-online-space-booking-on-mobile-screen.webp?length=1280&name=ungerboeck-online-space-booking-on-mobile-screen.webp", // Add the image path or URL here
    desc: "Our fast and easy booking process ensures that you can secure your travel arrangements in just a few clicks. With a user-friendly online platform, you can quickly browse options, customize your itinerary, and complete your reservation—all in minutes. Plus, our dedicated customer support team is available to assist you at any stage, making your travel planning experience smooth and hassle-free.",
  },

  {
    id: 5,
    title: "Many Pickup Locations",
    icon: "ri-map-pin-line",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR_PpRp0kemS-zq6D8Fdr9oodfxsNHBaZS2og&s", // Add the image path or URL here
    desc: "We provide convenience with multiple pickup locations throughout the city, making it easy for you to start your journey wherever you are. Whether you're at the airport, your hotel, or a popular landmark, our flexible pickup options ensure that you can access our services with minimal hassle. Enjoy the freedom to choose the most convenient spot for your travel needs and experience seamless transportation at your fingertips.",
  },

  {
    id: 6,
    title: "Airport Transfer",
    icon: "ri-flight-takeoff-line",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKSecFZLVcXuSF8U7yfqYVlkE3ruC5YjeiNQ&s", // Add the image path or URL here
    desc: "Our airport transfer service offers a hassle-free way to travel to and from the airport. With professional drivers and a range of vehicle options, you’ll enjoy a comfortable and reliable ride, whether you're arriving or departing. We monitor flight schedules to ensure timely pickups, allowing you to relax and focus on your journey. Experience convenience and peace of mind with our seamless airport transfer service.",
  },
];

export default serviceData;
