import React from "react";
import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import BecomeDriverSection from "../components/UI/BecomeDriverSection";
import driveImg from "../assets/all-images/bb.jpg";
import "../styles/about.css";
import FloatingButtons from "../components/UI/FloatingButtons";
const About = () => {
  return (
    <Helmet title="About">
      <CommonSection title="About Us" />
      <AboutSection aboutClass="aboutPage" />

      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  We Are Committed To Provide Safe Ride Solutions
                </h2>

                <p className="section__description">
                  At Shri Guru Kripa Tours and Services, your safety and
                  security are our top priorities. Every vehicle in our fleet
                  undergoes regular maintenance and safety checks to ensure it
                  meets the highest standards of performance and reliability.
                  Our drivers are thoroughly vetted, trained, and experienced,
                  ensuring that you are always in safe hands. Whether you're
                  traveling day or night, near or far, you can trust us to
                  provide a secure and worry-free ride experience. We are
                  dedicated to delivering peace of mind with every journey.
                </p>

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+91- 9950171777</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <FloatingButtons></FloatingButtons>

      <BecomeDriverSection />

      <section>
        <Container>
          <Row></Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default About;
