import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/cars-img/aa.jpg";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className={`about__section ${aboutClass}`}
      style={{
        marginTop: aboutClass === "aboutPage" ? "0px" : "200px",
        backgroundColor: "#f8f9fa",
        borderRadius: "16px",
        padding: "60px 0",
        boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Container>
        <Row className="align-items-center">
          <Col lg="6" md="6">
            <div className="about__content fade-in">
              <h4 className="section__subtitle mb-2">About Us</h4>
              <h2 className="section__title mb-4">
                Welcome to <span className="brand-name">SHRI GURU KRIPA</span>
              </h2>
              <p className="section__description">
                We at Shri Guru Kripa Tours and Services prioritize your{" "}
                <b>safety</b>, <b>comfort</b>, and <b>satisfaction</b>. Whether
                it’s a city ride or a long-distance journey, our professional
                drivers and well-maintained fleet ensure an exceptional
                experience every time. Enjoy premium services without
                compromising affordability or quality.
              </p>

              <ul className="about__features mt-4">
                <li>
                  <i className="ri-shield-check-line feature-icon"></i>
                  Your Safety, Our Responsibility
                </li>
                <li>
                  <i className="ri-heart-2-line feature-icon"></i>
                  Comfort is Our Priority
                </li>
                <li>
                  <i className="ri-car-line feature-icon"></i>
                  Well-Maintained Vehicles
                </li>
                <li>
                  <i className="ri-service-line feature-icon"></i>
                  Quality Service, Every Time
                </li>
              </ul>
            </div>
          </Col>

          <Col lg="6" md="6" className="text-center">
            <div className="about__image">
              <img
                src={aboutImg}
                alt="About Us"
                className="img-fluid rounded shadow-lg hover-scale"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
