import React from "react";
// Optional: import the FontAwesome icon
import "@fortawesome/fontawesome-free/css/all.min.css";

const WhatsAppButton = () => {
  const phoneNumber = "919950171777"; // Your WhatsApp number (include country code, no "+" sign)
  const message = encodeURIComponent("Hello! I would like more information.");

  const handleWhatsAppRedirect = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`,
      "_blank"
    );
  };

  return (
    <button
      onClick={handleWhatsAppRedirect}
      style={{
        backgroundColor: "#013220",
        color: "white",
        border: "none",
        padding: "8px 12px",
        borderRadius: "10px",
        cursor: "pointer",
        fontSize: "16px",
        marginTop: "10px",
      }}
    >
      <i className="fab fa-whatsapp"></i> WhatsApp US
    </button>
  );
};

export default WhatsAppButton;
