// FloatingButtons.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons"; // Phone icon from solid
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"; // WhatsApp icon from brands
import "../../styles/FloatingButtons.css"; // Import your CSS styles

const FloatingButtons = () => {
  return (
    <div className="floating-buttons">
      <a href="tel:+919950071777" className="floating-button call-button">
        <FontAwesomeIcon icon={faPhone} />
      </a>
      <a
        href="https://wa.me/+919950071777"
        className="floating-button whatsapp-button"
      >
        <FontAwesomeIcon icon={faWhatsapp} />
      </a>
    </div>
  );
};

export default FloatingButtons;
