// import all images from assets/images directory
import img01 from "../all-images/cars-img/deziree.jpg";
import img02 from "../all-images/cars-img/dezire.jpg";
import img03 from "../all-images/cars-img/newDezire.png";
import img04 from "../all-images/cars-img/innova.png";
import img05 from "../all-images/cars-img/InnovaCrysta.png";
import img06 from "../all-images/cars-img/innovaH.png";
import img07 from "../all-images/cars-img/Baleno.jpg";
import img08 from "../all-images/cars-img/brezza.png";
import img09 from "../all-images/cars-img/FORTUNER.png";
import img10 from "../all-images/cars-img/fortunerOld.png";
import img11 from "../all-images/cars-img/tempo12.png";
import img12 from "../all-images/cars-img/tempo16.png";
import img13 from "../all-images/cars-img/tempInt.jpg";
import img14 from "../all-images/cars-img/urbania.jpg";
import img15 from "../all-images/cars-img/urbania14.jpeg";
import img16 from "../all-images/cars-img/mercedes-offer.png";
import img17 from "../all-images/cars-img/mercedesE.png";
import img18 from "../all-images/cars-img/oldErtiga.jpg";
import img19 from "../all-images/cars-img/newErtigaa.jpg";
import img20 from "../all-images/cars-img/CIAZ.png";
const carData = [
  {
    id: 1,
    brand: "Maruti Suzuki",
    rating: 5,
    carName: "Old Dezire",
    imgUrl: img01,
    price: 10,
    gps: "GPS Navigation",
    seatType: "4+1",
    automatic: "Manual",
    description: "DIESEL.",
    type: "one-way",
  },

  {
    id: 2,
    brand: "Maruti Suzuki",
    rating: 4,
    carName: "Dzire",
    imgUrl: img02,
    price: 11,
    gps: "GPS Navigation",
    seatType: "4 + 1",
    automatic: "Manual",
    description: " DIESEL.",
  },

  {
    id: 3,
    brand: "Suzuki",
    rating: 5,
    carName: "New Dzire",
    imgUrl: img03,
    price: 12,
    gps: "GPS Navigation",
    seatType: " 4 +1",
    automatic: "Mannual",
    description: "   PETROL",
  },

  {
    id: 4,
    brand: "Innova",
    rating: 5,
    carName: "Innova",
    imgUrl: img04,
    price: 16,
    gps: "GPS Navigation",
    seatType: "6+!",
    automatic: "Mannual",
    description: " DIESEL.",
  },

  {
    id: 5,
    brand: "Innova Crysta",
    rating: 5,
    carName: "Innova Crysta",
    imgUrl: img05,
    price: 18,
    gps: "GPS Navigation",
    seatType: "6+1",
    automatic: "Mannual",
    description: "   DIESEL",
  },

  {
    id: 6,
    brand: "Innova Hycross",
    rating: 5,
    carName: "Innova Hycross",
    imgUrl: img06,
    price: 20,
    gps: "GPS Navigation",
    seatType: "6+1",
    automatic: "Mannual",
    description: "PETROL.",
  },

  {
    id: 7,
    brand: "Baleno",
    rating: 4,
    carName: "Baleno",
    imgUrl: img07,
    price: 12,
    gps: "GPS Navigation",
    seatType: "4+1",
    automatic: "Mannual",
    description: " PETROL",
  },

  {
    id: 8,
    brand: "Brezza",
    rating: 5,
    carName: "Brezza",
    imgUrl: img08,
    price: 13,
    gps: "GPS Navigation",
    seatType: "4+1",
    automatic: "Mannual",
    description: " PETROL.",
  },

  {
    id: 9,
    brand: "Fortuner",
    rating: 5,
    carName: "Fortuner",
    imgUrl: img09,
    price: 25,
    gps: "GPS Navigation",
    seatType: "6+1",
    automatic: "Mannual",
    description: " DIESEL.",
  },

  {
    id: 10,
    brand: "NEW FORTUNER",
    rating: 5,
    carName: "New Fortuner",
    imgUrl: img10,
    price: 35,
    gps: "GPS Navigation",
    seatType: "6+1",
    automatic: "Mannual",
    description: " DIESEL.",
  },

  {
    id: 11,
    brand: "TEMPO TRAVELLER",
    rating: 5,
    carName: "Tempo Traveller",
    imgUrl: img11,
    price: 23,
    gps: "GPS Navigation",
    seatType: "12+1",
    automatic: "Mannual",
    description: " DIESEL.",
  },

  {
    id: 12,
    brand: "TEMPO TRAVELLER",
    rating: 5,
    carName: "Tempo Traveller",
    imgUrl: img12,
    price: 25,
    gps: "GPS Navigation",
    seatType: "17+1",
    automatic: "Mannual",
    description: " DIESEL.",
  },

  {
    id: 13,
    brand: "TEMPO TRAVELLER",
    rating: 5,
    carName: "Tempo Traveller",
    imgUrl: img13,
    price: 30,
    gps: "GPS Navigation",
    seatType: "23+1",
    automatic: "Mannual",
    description: " DIESEL.",
  },

  {
    id: 14,
    brand: "URBANIA",
    rating: 5,
    carName: "Urbania",
    imgUrl: img14,
    price: 35,
    gps: "GPS Navigation",
    seatType: "17+1",
    automatic: "Mannual",
    description: " DIESEL.",
  },

  {
    id: 15,
    brand: "URBANIA",
    rating: 5,
    carName: "Urbania",
    imgUrl: img15,
    price: 30,
    gps: "GPS Navigation",
    seatType: "14+1",
    automatic: "Mannual",
    description: " DIESEL.",
  },

  {
    id: 16,
    brand: "MERCEDES S CLASS",
    rating: 5,
    carName: "Mercedes S Class",
    imgUrl: img16,
    price: 70,
    gps: "GPS Navigation",
    seatType: "4+1",
    automatic: "Mannual",
    description: " DIESEL & PETROL.",
  },

  {
    id: 17,
    brand: "MERCEDES E CLASS",
    rating: 5,
    carName: "Mercedes E Class",
    imgUrl: img17,
    price: 50,
    gps: "GPS Navigation",
    seatType: "4+1",
    automatic: "Mannual",
    description: " DIESEL & PETROL.",
  },

  {
    id: 18,
    brand: "OLD ERTIGA",
    rating: 5,
    carName: "Old Ertiga",
    imgUrl: img18,
    price: 13,
    gps: "GPS Navigation",
    seatType: "6+1",
    automatic: "Mannual",
    description: " DIESEL.",
  },

  {
    id: 19,
    brand: "NEW ERTIGA",
    rating: 5,
    carName: "New Ertiga",
    imgUrl: img19,
    price: 14,
    gps: "GPS Navigation",
    seatType: "6+1",
    automatic: "Mannual",
    description: " PETROL.",
  },

  {
    id: 20,
    brand: "",
    rating: 5,
    carName: "CIAZ",
    imgUrl: img20,
    price: 12,
    gps: "GPS Navigation",
    seatType: "4+1",
    automatic: "Manual",
    description: "DIESEL.",
    type: "one-way",
  },
];

export default carData;
