import React from "react";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import WhatsAppButton from "../components/UI/WhatsApp";
import "../styles/contact.css";
import FloatingButtons from "../components/UI/FloatingButtons";

const socialLinks = [
  {
    url: "https://www.facebook.com/profile.php?id=61557672637840&mibextid=ZbWKwL",
    icon: "fa-brands fa-facebook-f",
  },
  {
    url: "https://www.instagram.com/shrigurukripatourandtaxi?igsh=MWduOHBvODA4OWs4aQ==",
    icon: "fa-brands fa-instagram",
  },
  {
    url: "https://g.co/kgs/buRqvYy",
    icon: "fa-brands fa-google",
  },
  {
    url: "https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://www.justdial.com/Ajmer/Shri-Gurukripa-Tour-and-Taxi-Service-Near-Maya-Bazaar-Old-Bus-Stand-Madanganj-Kishangarh/9999PX145-X145-181205123326-D1A2_BZDET&ved=2ahUKEwj1j7yq3-iIAxWqUGcHHWywLt4QFnoECE0QAQ&usg=AOvVaw3iny6-3idBBSOueR0ZT2xm",
    icon: "fa-brands fa-searchengin",
  },
];

const Contact = () => {
  return (
    <Helmet title="Contact">
      <CommonSection title="Contact" />
      <section>
        <Container>
          <Row>
            {/*  <Col lg="7" md="7">
              
              <h6 className="fw-bold mb-4">Get In Touch</h6>

              <Form>
                <FormGroup className="contact__form">
                  <Input placeholder="Your Name" type="text" />
                </FormGroup>
                <FormGroup className="contact__form">
                  <Input placeholder="Email" type="email" />
                </FormGroup>
                <FormGroup className="contact__form">
                  <textarea
                    rows="5"
                    placeholder="Message"
                    className="textarea"
                  ></textarea>
                </FormGroup>

                <button className=" contact__btn" type="submit">
                  Send Message
                </button>
              </Form>
             
            </Col> */}

            <Col lg="5" md="5">
              <div className="contact__info">
                <h6 className="fw-bold">Contact Information</h6>
                <p className="section__description mb-0">
                  Near Saint Stephen School , Plot No.52, Madanganj-Kishangarh
                  (305801)
                </p>
                <div className="d-flex align-items-center gap-2">
                  <h6 className="fs-6 mb-0">Phone:</h6>
                  <p className="section__description mb-0">+91-9950071777</p>
                </div>

                <div className="d-flex align-items-center gap-2">
                  <h6 className="mb-0 fs-6">Email:</h6>
                  <p className="section__description mb-0">
                    sgktravels.kishangarh@gmail.com
                  </p>
                </div>

                <div className="header__top__left">
                  <WhatsAppButton style={{ marginTop: "20px" }} />
                </div>

                <div
                  className="header__top__left"
                  style={{ marginTop: "20px" }}
                >
                  {socialLinks.map((item, index) => (
                    <a
                      key={index}
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="social__link"
                    >
                      <i className={item.icon}></i>
                    </a>
                  ))}
                  <FloatingButtons></FloatingButtons>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Contact;
