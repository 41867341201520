import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import CarItem from "../components/UI/CarItem";
import carData from "../assets/data/carData";
import FloatingButtons from "../components/UI/FloatingButtons";

const CarListing = () => {
  // State to track the selected option from the dropdown
  const [selectedOption, setSelectedOption] = useState("");

  // Filter data based on selected option
  const filteredCarData =
    selectedOption === "one-way"
      ? carData.filter((item) => item.type === "one-way")
      : carData;

  // Handler for the select dropdown
  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  };

  return (
    <Helmet title="Cars">
      <CommonSection title="Car Listing" />

      <section>
        <Container>
          <Row>
            <Col lg="12">
              <div className="d-flex align-items-center gap-3 mb-5">
                <span className="d-flex align-items-center gap-2">
                  <i className="ri-sort-asc"></i> Sort By
                </span>

                {/*  <select onChange={handleSelectChange}>
                  <option value="">Select</option>
                  <option value="one-way">One Way Ride</option>
                </select> */}
              </div>
            </Col>

            {/* Conditionally render filtered car data */}
            {filteredCarData.map((item) => (
              <CarItem item={item} key={item.id} />
            ))}
          </Row>
        </Container>
        <FloatingButtons></FloatingButtons>
      </section>
    </Helmet>
  );
};

export default CarListing;
